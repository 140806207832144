<template>
  <b-modal
    v-model="conditionCancel"
    @hide="onHide"
    hide-footer
    hide-header
    centered
    size="md"
  >
    <div v-if="insuranceData">
      <p
        v-for="{ note } in insuranceData.notes"
        class="alert alert-warning p-1 text-center"
      >
        {{ note }}
      </p>
    </div>
    <validation-observer #default="{ handleSubmit }">
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="handleSubmit(onSubmitCancel)"
      >
        <validation-provider #default="{ errors }" name="Type" rules="required">
          <b-form-group
            label="Total Pending"
            label-for="total_pending"
            v-if="insuranceData"
          >
            <b-form-input
              disabled
              :value="insuranceData.total_pending_payment_amount"
            />
          </b-form-group>
          <b-form-group
            label="Type"
            label-for="type"
            :state="errors.length > 0 ? false : null"
            class="my-1"
          >
            <v-select
              id="type"
              v-model="formRefund.other_charge_type_id"
              :options="typeOptions"
              :reduce="(value) => value.id"
              label="name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <b-row>
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="cash"
              rules="decimal"
            >
              <b-form-group label="Cash" label-for="cash">
                <b-form-input
                  id="cash"
                  name="cash"
                  v-model="formRefund.cash.value"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Transaction Id"
              :rules="formRefund.cash.value ? 'required' : ''"
            >
              <b-form-group
                label="Cash Transaction Id"
                label-for="Transaction Id"
              >
                <b-form-input
                  id="transaction_id_cash"
                  name="Transaction Id"
                  :disabled="!formRefund.cash.value"
                  v-model="formRefund.cash.transaction_id"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Credit card"
              rules="decimal"
            >
              <b-form-group label="Credit card" label-for="Credit card">
                <b-form-input
                  id="Credit_card"
                  name="Credit card"
                  v-model="formRefund.cc.value"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="CC Transaction ID"
              :rules="formRefund.cc.value ? 'required' : ''"
            >
              <b-form-group
                label="CC Transaction ID"
                label-for="CC Transaction ID"
              >
                <b-form-input
                  id="cc_transaction_id"
                  name="CC Transaction ID"
                  :disabled="!formRefund.cc.value"
                  v-model="formRefund.cc.transaction_id"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- internal payment note-->
        <validation-provider
          #default="validationContext"
          name="Note"
          rules="required"
        >
          <b-form-group
            label="Note"
            label-for="internal-payment-note"
            class="my-1"
          >
            <b-form-textarea
              id="internal-payment-note"
              v-model="formRefund.description"
              placeholder="Internal payment note"
              no-resize
              rows="3"
            />
            <small class="text-danger">{{ validationContext.errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <div class="d-flex justify-content-end mt-2">
          <b-button
            type="button"
            class="mr-2"
            variant="outline-danger"
            @click="onHide"
          >
            {{ $t("Cancel") }}
          </b-button>
          <b-button variant="warning" type="submit" :disabled="loading">
            <span v-if="!loading">Send</span>
            <span v-else>
              <SpinnerLoading />
            </span>
          </b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTextarea,
  BCol,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

import SpinnerLoading from "@/components/SpinnerLoading";

import axiosA from "@/services/appointment";

import NotificationMixin from "@/mixins/NotificationMixin";

export default {
  components: {
    BModal,
    BForm,
    BCol,
    BRow,
    BFormGroup,
    BFormInput,
    BButton,
    BFormTextarea,

    flatPickr,
    vSelect,

    ValidationProvider,
    ValidationObserver,

    SpinnerLoading,
  },
  props: {
    appointmentById: {
      type: Number,
      required: true,
    },
    openModal: {
      type: Boolean,
      required: false,
      default: false,
    },
    insuranceData: {
      type: Object,
      required: false,
    },
  },
  mixins: [NotificationMixin],
  data() {
    return {
      conditionCancel: true,
      conditionSplit: true,
      loading: false,
      formRefund: {
        is_split_pay: false,
        description: null,
        other_charge_type_id: null,
        cash: {
          value: 0,
          transaction_id: null,
        },
        cc: {
          value: 0,
          transaction_id: null,
        },
      },
      typeOptions: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    onHide() {
      this.$emit("closeModalPay", false);
    },
    getData() {
      axiosA
        .otherChargesTypesPaginated(25, 1)
        .then(({ registro: { data } }) => {
          this.typeOptions = data;
          if (this.openModal == true) {
            this.typeOptions.push({ name: "Deductible", id: "deductible" });
            this.typeOptions.push({ name: "Copay", id: "copay" });
            this.typeOptions.push({ name: "Co Insurance", id: "coinsurance" });
          }
        });
    },
    async onSubmitCancel() {
      this.loading = true;
      if (isNaN(this.formRefund.other_charge_type_id)) return this.onPayingInsurance();
      this.submitOtherCharges();
    },
    async submitOtherCharges() {
      const data = {
        ...this.formRefund,
      };
      await axiosA
        .otherChargesPayment(this.appointmentById, data)
        .then((res) => {
          const { type, message } =
            NotificationMixin.methods.notificationCheck(res);
          this.$emit("NotificationCheck", { type, message });
          this.$emit("paymentChecked", true);
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
      this.$emit("closeModalPay", false);
    },
    async onPayingInsurance() {
      if (
        +this.formRefund.cash.value + +this.formRefund.cc.value !=
        this.insuranceData.total_pending_payment_amount
      )
        return this.$emit("NotificationCheck", {
          status: "ERROR",
          message: "The values doesn't match with the total pending!",
        });

      const data = {
        ...this.formRefund,
      };
      data.type_insurance = this.formRefund.other_charge_type_id
      data.insurance_id = this.insuranceData.insurance;
      data.total_pending = this.insuranceData.total_pending_payment_amount;
      data.is_split_pay = data.cc.value && data.cash.value;

      const res = await axiosA.payPendingInsurances(data);
      const { type, message } =
        NotificationMixin.methods.notificationCheck(res);
      this.$emit("NotificationCheck", { type, message });
      this.$emit("paymentChecked", true);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
