<template>
  <div>
    <PatientFilter v-if="$can('filter', 'module_patient.patient')" :statusOptions="statusOptions" :loading="loading"
      @searchFilter="searchFilter" @filterPage="filterPage" />
    <b-card class="table-expand">
      <TitleTable titleTable="Patients" iconTable="UsersIcon" />
      <div class="m-1">
        <PendingPayment v-if="conditionAdP" :appointmentById="appointmentById"
          @closeModalPay="conditionAdP = false" @NotificationCheck="notificationCheck" />
        <PatientAdd v-if="isAddNewPatient" :isScanQrPatient="isScanQrPatient"
          :isInsuranceMedicalCenter="isMenuMedicalCenter" @closeModalPatient="closeModalAppoinment"
          @createPatient="createPatient" />
        <AppointmentAddV2 v-if="isAddNewAppointmentSidebarActive" :userAppointment="userAppointment"
          :isInsuranceMedicalCenter="isMenuMedicalCenter" @closeModalAppoinment="closeModalAppoinment"
          @createAppointment="createAppointment" />
        <PatientMembership v-if="isAddNewQrPatient" @closeModalQrPatient="closeModalQrPatient"
          @closeModalPay="closeModalMembership" @notificationCheck="notificationCheck" />
        <AppointmentMembership v-if="isUserNewMembership" :userId="userAppointment"
          @closeModalPay="closeModalNewMembership" @notificationCheck="notificationCheck"
          @successModalQr="successModalQr" />
        <b-row>
          <b-col cols="12" md="12" class="d-flex align-items-center justify-content-between flex-wrap mb-1 mb-md-0">
            <div>
              <label>{{ $t("Show") }}</label>
              <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
                :clearable="false" class="per-page-selector d-inline-block mx-50" />
              <label>{{ $t("Entries") }}</label>
            </div>

            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-1" />
              <div v-if="!isMenuMedicalCenter">
                <b-button variant="primary" @click="isAddNewQrPatient = true" :disabled="loading" class="mr-1">
                  <span v-if="!loading" class="text-nowrap">Scan Qr Membership</span>
                  <span v-else>
                    <SpinnerLoading />
                  </span>
                </b-button>
              </div>

              <b-button variant="primary" @click="isAddNewPatient = true" :disabled="loading"
                v-if="$can('store', 'module_patient.patient')">
                <span v-if="!loading" class="text-nowrap">{{ $t("Add") }} {{ $t("Patient") }}</span>
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <LoadingTable v-if="items === null" :columns="fields.length" />
      <b-table v-else :items="items" :fields="fields" striped bordered responsive hover class="position-relative"
        primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc">
        <template #cell(show)="row">
          <b-form-checkbox v-model="row.detailsShowing" plain class="vs-checkbox-con" @change="row.toggleDetails">
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-card>
            <b-row class="mb-2">
              <b-col md="4" class="mb-1">
                <strong>Full Name : </strong>{{ row.item.name }}
                {{ row.item.lastname }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Identification : </strong>{{ row.item.identification }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>Email : </strong>{{ row.item.email }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>{{ $t("Form.City") }} : </strong>{{ row.item.cities_id }}
              </b-col>
              <b-col md="4" class="mb-1">
                <strong>{{ $t("Form.Gender") }} : </strong>{{ row.item.gender }}
              </b-col>
            </b-row>

            <b-button size="sm" variant="outline-secondary" @click="row.toggleDetails">
              Hide Details
            </b-button>
          </b-card>
        </template>

        <template #cell(patient)="data">
          <b-media vertical-align="center">
            <b-link :to="{
              name: 'patients-view',
              params: { id: data.item.id, isShow: false },
            }">
              <span class="font-weight-bold d-block text-nowrap">
                {{ data.item.name }} {{ data.item.lastname }}
              </span>
              <small class="text-muted">{{ data.item.email }}</small>
            </b-link>
          </b-media>
        </template>

        <template #cell(status)="data">
          <div class="text-nowrap">
            <b-badge :variant="resolveUserStatusVariant(data.item.status)" class="badge-glow cursor-pointer">
              <span class="align-text-top text-capitalize">{{
                data.item.status ? $t("Active") : $t("Inactive")
              }}</span>
            </b-badge>
          </div>
        </template>

        <template #cell(phone)="data">
          <div class="text-nowrap">
            <span v-if="data.item.phone" class="align-text-top text-capitalize">{{ formatPhone(data.item.phone)
              }}</span>
          </div>
        </template>

        <template #cell(visit_number)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{
              data.item.histories && data.item.histories.visit_number
            }}</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <PatientOptionsMedicalCenter v-if="isMenuMedicalCenter" :loading="loading" :dataOption="data.item"
            @addAppointment="addAppointment" />

          <div v-else class="text-nowrap">
            <b-button v-if="!data.item.membership" variant="flat-primary" class="btn-icon btn-sm"
              v-b-tooltip.hover.top="'Add Membership'" @click="addMembership(data.item.id)">
              <feather-icon icon="CreditCardIcon" size="16" />
            </b-button>
            <b-button variant="flat-info" class="btn-icon btn-sm" v-b-tooltip.hover.top="'Add Appointment'"
              v-if="$can('store', 'module_patient.resource_schedule')" @click="addAppointment(data.item)">
              <feather-icon icon="PlusSquareIcon" size="16" />
            </b-button>
            <b-button variant="flat-warning" class="btn-icon btn-sm" v-b-tooltip.hover.top="'View Patient'"
              v-if="$can('show', 'module_patient.patient')" :to="{
                name: 'patients-view',
                params: { id: data.item.id, isShow: false },
              }">
              <feather-icon icon="FileTextIcon" size="16" />
            </b-button>
            <b-button variant="flat-warning" class="btn-icon btn-sm" v-b-tooltip.hover.top="'Edit Patient'"
              v-if="$can('update', 'module_patient.patient')" :to="{
                name: 'patients-view',
                params: { id: data.item.id, isShow: true },
              }">
              <feather-icon icon="EditIcon" size="16" />
            </b-button>
            <!-- <b-button
              variant="flat-danger"
              class="btn-icon btn-sm"
              v-b-tooltip.hover.top="'Delete Patient'"
              v-if="$can('destroy', 'patients')"
              @click="deletePatient(data.item)"
            >
              <feather-icon icon="Trash2Icon" size="16" />
            </b-button> -->
            <b-button variant="flat-warning" class="btn-icon" v-b-tooltip.hover.top="'Additional Payment'"
              @click="additionalPayment(data.item.id)">
              <feather-icon icon="CheckCircleIcon" />
            </b-button>
          </div>
        </template>
      </b-table>
      <PaginationTable :dataMeta="dataMeta" :totalUsers="totalUsers" :perPage="perPage" :currentPage="currentPage"
        @page-changed="pageChanged" />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BTable,
  BFormCheckbox,
  BButton,
  BCard,
  BRow,
  BCol,
  BMedia,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  VBTooltip,
} from "bootstrap-vue";

import vSelect from "vue-select";
import GoBack from "@/components/ButtonBack";
import SpinnerLoading from "@/components/SpinnerLoading";
import TitleTable from "@/components/TitleTable.vue";
import ToastNotification from "@/components/ToastNotification";
import PaginationTable from "@/components/PaginationTable";
import LoadingTable from "@/components/LoadingTable.vue";

import PatientAdd from "@/views/patients/patients-actions/PatientAdd.vue";
import PatientFilter from "@/views/patients/patients-actions/PatientFilter.vue";
import AppointmentAddV2 from "@/views/appointment/appointment-actions/AppointmentAddV2.vue";
import PatientMembership from "@/views/patients/patients-actions/PatientMembership.vue";
import PatientOptionsMedicalCenter from "@/views/patients/patients-actions/PatientOptionsMedicalCenter.vue";
import AppointmentMembership from "@/components/scanQr.vue";

import { formatPhone } from "@/mixins/formatPhone.js";

import axiosP from "@/services/patient";
import NotificationMixin from "@/mixins/NotificationMixin";
import PendingPayment from "../patients-actions/PendingPayment.vue";

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BLink,
    BDropdown,
    BDropdownItem,
    BMedia,
    GoBack,
    PendingPayment,
    SpinnerLoading,
    ToastNotification,
    PaginationTable,
    LoadingTable,

    vSelect,
    TitleTable,
    PatientAdd,
    PatientFilter,
    AppointmentAddV2,
    PatientMembership,
    PatientOptionsMedicalCenter,
    AppointmentMembership,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  mixins: [NotificationMixin],
  data() {
    return {
      formatPhone,
      fields: [
        "show",
        { key: "id", sortable: true },
        { key: "patient", sortable: true },
        { key: "phone" },
        { key: "date_birth", label: "DOB", sortable: true },
        { key: "visit_number", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "actions" },
      ],
      items: null,
      perPageOptions: [10, 25, 50, 100],
      statusOptions: [
        { text: "Active", value: "true" },
        { text: "Inactive", value: "false" },
      ],
      perPage: 50,
      loading: false,
      filtro: false,
      isSortDirDesc: false,
      sortBy: "id",
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      currentPage: {
        page: 1,
      },
      totalUsers: 0,
      isUserNewMembership: false,
      isAddNewPatient: false,
      isAddNewAppointmentSidebarActive: false,
      isAddNewQrPatient: false,
      isScanQrPatient: null,
      userAppointment: null,
      patientFilters: null,
      isMenuMedicalCenter: false,
      conditionAdP: false,
      appointmentById: 0,
    };
  },
  beforeRouteEnter(to, from, next) {
    let isAddNewPatient = false;
    if (to.meta.isAddPatient) {
      isAddNewPatient = true;
    }
    next((vm) => {
      vm.isAddNewPatient = isAddNewPatient;
    });
  },

  beforeRouteUpdate(to, from, next) {
    let isAddNewPatient = false;
    if (to.meta.isAddPatient) {
      isAddNewPatient = true;
    }
    this.isAddNewPatient = isAddNewPatient;
    next();
  },
  mounted() {
    this.getPatients();
    const facilityInUse = this.$variableGlobal.isMedicalCenter;
    if (facilityInUse) {
      this.isMenuMedicalCenter = true;
    }
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getPatients(newVal);
      } else {
        this.searchFilter(this.patientFilters);
      }
    },
    "$variableGlobal.facility": function (value) {
      this.getPatients();
    },
    "$variableGlobal.isMedicalCenter": function (value) {
      this.isMenuMedicalCenter = value;
    },
  },
  methods: {
    additionalPayment(item) {
      this.conditionAdP = true;
      this.appointmentById = item;
    },
    getPatients() {
      axiosP
        .patientList(this.perPage)
        .then(({ data, total, current_page, ...res }) => {
          const memberData = data.map((item) => {
            if (item.memberships.length > 0) {
              return {
                ...item,
                _rowVariant: "gold",
              };
            }
            return item;
          });
          this.items = memberData;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        });
    },
    closeModalQrPatient(data) {
      const { status, registro, qr } = data;
      if (status === "ERROR") {
        this.$swal({
          title: "Error!",
          text: data.message,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else if (status === "VALIDATION") {
        this.isAddNewPatient = true;
        this.isScanQrPatient = qr;
      } else {
        this.userAppointment = registro;
        this.isAddNewAppointmentSidebarActive = true;
      }
      this.isAddNewQrPatient = false;
    },
    resolveUserStatusVariant(status) {
      if (status) return "success";
      return "danger";
    },
    createPatient({ type, message }) {
      this.notificationCheck({ type, message });

      if (type === "ERROR") {
        this.$swal({
          title: "Error!",
          text: message,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.isAddNewPatient = false;
      this.pageChanged(this.currentPage.page);
    },
    deletePatient(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosP.patientDelete(item.id).then((response) => {
            this.$refs.toast.success("Patient has been deleted successfully");
            this.pageChanged(this.currentPage.page);
          });
        }
      });
    },
    addAppointment(item) {
      this.userAppointment = item;
      this.isAddNewAppointmentSidebarActive = true;
    },
    addMembership(userId) {
      this.userAppointment = userId;
      this.isUserNewMembership = true;
    },
    createAppointment({ type, message }) {
      this.notificationCheck({ type, message });

      if (type !== "danger") {
        this.isAddNewAppointmentSidebarActive = false;
      }
      this.pageChanged(this.currentPage.page);
    },
    closeModalAppoinment() {
      this.isAddNewAppointmentSidebarActive = false;
      this.isAddNewPatient = false;
      this.isScanQrPatient = null;
    },
    closeModalNewMembership() {
      this.isUserNewMembership = false;
      this.userAppointment = null;
      this.pageChanged(this.currentPage.page);
    },
    closeModalMembership() {
      this.isAddNewQrPatient = false;
    },
    searchFilter(value) {
      this.loading = true;
      this.patientFilters = value;
      axiosP
        .patientFilter(this.perPage, value)
        .then(({ data, total, current_page, ...res }) => {
          this.loading = false;
          const memberData = data.map((item) => {
            if (item.memberships.length > 0) {
              return {
                ...item,
                _rowVariant: "gold",
              };
            }
            return item;
          });
          this.items = memberData;
          this.totalUsers = total;
          this.dataMeta.of = total;
          this.dataMeta.from = res.from;
          this.dataMeta.to = res.to;
          this.currentPage.page = current_page;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    filterPage(value) {
      this.filtro = value;
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosP
          .patientPagination(this.perPage, page)
          .then(({ data, total, current_page, ...res }) => {
            const memberData = data.map((item) => {
              if (item.membership) {
                return {
                  ...item,
                  _rowVariant: "gold",
                };
              }
              return item;
            });
            this.items = memberData;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      } else {
        axiosP
          .patientFilterPagination(this.perPage, page, this.patientFilters)
          .then(({ data, total, current_page, ...res }) => {
            this.items = data;
            this.totalUsers = total;
            this.dataMeta.of = total;
            this.dataMeta.from = res.from;
            this.dataMeta.to = res.to;
            this.currentPage.page = current_page;
          });
      }
    },
    successModalQr({ registro, status, qr, message = "Cupon or campaign not found!" }) {
      if (status != "ERROR") {
        this.pageChanged(this.currentPage.page);
        this.NotificationCheck({ type: "success", message })
        this.isScanQrPatient = qr ? qr : registro;
        this.isUserNewMembership = false;
        this.userAppointment = qr ? qr : registro;
        this.pageChanged(this.currentPage.page);
        return;
      }
      this.isUserNewMembership = false;
      this.userAppointment = null;
      this.pageChanged(this.currentPage.page);
      this.NotificationCheck({ type: "danger", message })
    },
    notificationCheck({ type, message }) {
      this.$refs.toast[type](message);
    },
  },
};
</script>
<style lang="scss" scoped>
.table-expand .card-header {
  padding: 0 1rem !important;
}
</style>
<style lang="scss">
.table-gold {
  background: rgb(241, 197, 0);
  background: linear-gradient(140deg,
      rgba(241, 197, 0, 1) 0%,
      rgba(255, 255, 255, 0) 12%);
}
</style>
