var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-add-new-patient","hide-footer":"","hide-header":"","size":"lg","centered":"","ignore-enforce-focus-selector":"#add-new-consult-sidebar"},on:{"hide":_vm.onHide},model:{value:(_vm.conditionAddNewPatient),callback:function ($$v) {_vm.conditionAddNewPatient=$$v},expression:"conditionAddNewPatient"}},[_c('div',{staticClass:"mb-13 mt-2 text-center"},[_c('h2',{staticClass:"mb-1"},[_vm._v("New Patient")]),_c('div',{staticClass:"text-muted"},[_vm._v(" If you need more info, please check see the "),_c('router-link',{staticClass:"fw-bold link-primary",attrs:{"to":"tutorial"}},[_vm._v(" tutorials")]),_vm._v(". ")],1)]),_c('validation-observer',{ref:"refFormObserver"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"mx-2"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Form.Name'),"label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","trim":"","placeholder":"First Name"},model:{value:(_vm.stateData.name),callback:function ($$v) {_vm.$set(_vm.stateData, "name", $$v)},expression:"stateData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{staticClass:"w-100 ml-2",attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Form.Last Name'),"label-for":"lastname"}},[_c('b-form-input',{attrs:{"id":"lastname","trim":"","placeholder":"Last Name"},model:{value:(_vm.stateData.lastname),callback:function ($$v) {_vm.$set(_vm.stateData, "lastname", $$v)},expression:"stateData.lastname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('validation-provider',{ref:"rulesContact",staticClass:"w-100",attrs:{"name":"Phone","rules":"required|max:14"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Form.Phone'),"label-for":"contact"}},[_c('b-form-input',{attrs:{"id":"contact"},model:{value:(_vm.formatPhone),callback:function ($$v) {_vm.formatPhone=$$v},expression:"formatPhone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{staticClass:"w-100 ml-2",attrs:{"name":"DOB","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"DOB","label-for":"date-birth"}},[_c('div',{staticClass:"d-flex flex-column"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.config,"placeholder":"MM-DD-YYYY"},model:{value:(_vm.stateData.date_birth),callback:function ($$v) {_vm.$set(_vm.stateData, "date_birth", $$v)},expression:"stateData.date_birth"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)])]}}])})],1),_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":"Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('b-form-input',{model:{value:(_vm.stateData.email),callback:function ($$v) {_vm.$set(_vm.stateData, "email", $$v)},expression:"stateData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{staticClass:"w-100 ml-2",attrs:{"name":"Gender","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Gender","label-for":"gender"}},[_c('v-select',{attrs:{"id":"type_consult","options":_vm.genderOptions,"reduce":function (value) { return value.value; },"label":"name"},model:{value:(_vm.stateData.gender),callback:function ($$v) {_vm.$set(_vm.stateData, "gender", $$v)},expression:"stateData.gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('validation-provider',{staticClass:"w-100",attrs:{"name":"Pick up medication","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Pick up medication","label-for":"pick-up"}},[_c('v-select',{attrs:{"id":"pick-up","options":_vm.medicationsOptions,"reduce":function (value) { return value.id; },"label":"name"},model:{value:(_vm.stateData.pick_up_medication_id),callback:function ($$v) {_vm.$set(_vm.stateData, "pick_up_medication_id", $$v)},expression:"stateData.pick_up_medication_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),(!_vm.isOnlyCreatePatient)?_c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":"Type Visit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Types of visits'),"label-for":"type visit"}},[_c('v-select',{attrs:{"id":"type visit","options":_vm.optionsTypeVisit,"reduce":function (option) { return option.id; },"clearable":false,"label":"name"},on:{"search":_vm.searchTypeVisit},model:{value:(_vm.stateData.type_visits_id),callback:function ($$v) {_vm.$set(_vm.stateData, "type_visits_id", $$v)},expression:"stateData.type_visits_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1760728990)}),_c('validation-provider',{staticClass:"w-100 ml-2",attrs:{"name":"Visit Reason","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Visit Reason'),"label-for":"visit_reason"}},[_c('v-select',{attrs:{"id":"type_consult","options":_vm.optionsTypeConsult,"reduce":function (option) { return option.id; },"clearable":false,"label":"name"},on:{"search":_vm.searchTypeConsult},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
return [(searching)?_c('NewItemSelect',{attrs:{"search":search},on:{"onSaveVisitReason":function($event){return _vm.onSaveVisitReason(search)}}}):_vm._e()]}}],null,true),model:{value:(_vm.stateData.type_consultations_id),callback:function ($$v) {_vm.$set(_vm.stateData, "type_consultations_id", $$v)},expression:"stateData.type_consultations_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2566506957)})],1),(!_vm.isInsuranceMedicalCenter && false)?_c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"me-5"},[_c('label',{staticClass:"fs-6 fw-semobold"},[_vm._v("Scan QR")])]),_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","disabled":_vm.stateData.isInsurance},on:{"change":function($event){return _vm.onClickScanQr($event)}},model:{value:(_vm.checkCodeQr),callback:function ($$v) {_vm.checkCodeQr=$$v},expression:"checkCodeQr"}})],1),_c('validation-provider',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkCodeQr),expression:"checkCodeQr"}],attrs:{"name":"Qr"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.checkCodeQrModal)?_c('AppointmentMembership',{on:{"closeModalPay":_vm.closeModalMembership,"successModalQr":_vm.successModalCupon,"notificationCheck":_vm.notificationCheckType}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,368940089)})],1):_vm._e(),(!_vm.isInsuranceMedicalCenter)?_c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center my-1"},[_c('div',{staticClass:"me-5"},[_c('label',{staticClass:"fs-6 fw-semobold"},[_vm._v("Scan Cupon")])]),_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},on:{"change":function($event){return _vm.onClickScanCupon($event)}},model:{value:(_vm.checkCupon),callback:function ($$v) {_vm.checkCupon=$$v},expression:"checkCupon"}})],1),_c('validation-provider',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkCupon),expression:"checkCupon"}],attrs:{"name":"Cupon"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.checkCuponModal)?_c('AppointmentMembership',{attrs:{"typeQr":"cupon"},on:{"successModalQr":_vm.successModalCupon,"closeModalPay":_vm.closeModalCupon,"notificationCheck":_vm.notificationCheckType}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3117176819)}),(_vm.cupon)?_c('b-input-group',{attrs:{"label":_vm.$t('Visit Reason'),"label-for":"visit_reason"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":_vm.noCupon}},[_vm._v("X")])]},proxy:true}],null,false,1945450251)},[_c('b-form-input',{attrs:{"disabled":""},model:{value:(_vm.cupon),callback:function ($$v) {_vm.cupon=$$v},expression:"cupon"}})],1):_vm._e()],1):_vm._e(),_c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center my-1"},[_c('div',{staticClass:"me-5"},[_c('label',{staticClass:"fs-6 fw-semobold"},[_vm._v("Insurance")])]),_c('b-form-checkbox',{attrs:{"disabled":_vm.checkCodeQr,"name":"check-button","switch":""},on:{"change":_vm.changeInsurance},model:{value:(_vm.stateData.isInsurance),callback:function ($$v) {_vm.$set(_vm.stateData, "isInsurance", $$v)},expression:"stateData.isInsurance"}})],1),(_vm.stateData.isInsurance)?_c('div',[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":"Insurance Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Insurance Type","label-for":"insurance-type"}},[_c('v-select',{attrs:{"id":"insurance-type","options":_vm.insuranceOptions,"reduce":function (value) { return value.id; },"label":"name"},on:{"search":_vm.searchTypeInsurance},model:{value:(_vm.stateData.insurance_type_id),callback:function ($$v) {_vm.$set(_vm.stateData, "insurance_type_id", $$v)},expression:"stateData.insurance_type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1465000725)})],1),_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":"Co-payment","rules":_vm.required},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Co-payment","label-for":"is-copay"}},[_c('v-select',{attrs:{"id":"is-copay","options":_vm.statusOptions,"reduce":function (value) { return value.value; },"label":"name"},model:{value:(_vm.stateData.isCoPayment),callback:function ($$v) {_vm.$set(_vm.stateData, "isCoPayment", $$v)},expression:"stateData.isCoPayment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1110258158)})],1),_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"d-flex w-50"},[_c('validation-provider',{attrs:{"name":"Credit Card","rules":_vm.stateData.isCoPayment ? 'required|decimal:3' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Credit Card","label-for":"cc"}},[_c('b-form-input',{attrs:{"name":"cc","disabled":!_vm.stateData.isCoPayment ||
                          _vm.isAddNewRecipeInsurancePaymentActive},on:{"change":_vm.changeCopaymentTotal},model:{value:(_vm.stateData.copay.payment.cc.value),callback:function ($$v) {_vm.$set(_vm.stateData.copay.payment.cc, "value", $$v)},expression:"stateData.copay.payment.cc.value"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3540851088)})],1),_c('div',{staticClass:"d-flex w-100 ml-2"},[_c('validation-provider',{attrs:{"name":"CC payment id","rules":_vm.stateData.isCoPayment && _vm.stateData.copay.payment.cc.value != 0 ? 'required|min:4' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CC payment Id","label-for":"cash"}},[_c('b-form-input',{attrs:{"name":"Transaction Clover Id","state":errors.length > 0 ? false : null,"disabled":!_vm.stateData.isCoPayment || _vm.isAddNewRecipeInsurancePaymentActive || _vm.stateData.copay.payment.cc.value == 0},model:{value:(_vm.stateData.copay.payment.cc.transaction_id),callback:function ($$v) {_vm.$set(_vm.stateData.copay.payment.cc, "transaction_id", $$v)},expression:"stateData.copay.payment.cc.transaction_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3620114192)})],1),_c('div',{staticClass:"d-flex w-50 ml-2"},[_c('validation-provider',{attrs:{"name":"Cash","rules":_vm.stateData.isCoPayment ? 'required|decimal:3' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Cash","label-for":"cash"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"name":"cash","disabled":!_vm.stateData.isCoPayment ||
                            _vm.isAddNewRecipeInsurancePaymentActive},on:{"change":_vm.changeCopaymentTotal},model:{value:(_vm.stateData.copay.payment.cash.value),callback:function ($$v) {_vm.$set(_vm.stateData.copay.payment.cash, "value", $$v)},expression:"stateData.copay.payment.cash.value"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2860478600)})],1),_c('div',{staticClass:"d-flex w-100 ml-2"},[_c('validation-provider',{attrs:{"name":"Cash Payment Id","rules":_vm.stateData.isCoPayment && _vm.stateData.copay.payment.cash.value != 0 ? 'required|min:4' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Cash Payment Id","label-for":"cash"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"name":"Transaction Clover Id","state":errors.length > 0 ? false : null,"disabled":!_vm.stateData.isCoPayment || _vm.isAddNewRecipeInsurancePaymentActive || _vm.stateData.copay.payment.cash.value == 0},model:{value:(_vm.stateData.copay.payment.cash.transaction_id),callback:function ($$v) {_vm.$set(_vm.stateData.copay.payment.cash, "transaction_id", $$v)},expression:"stateData.copay.payment.cash.transaction_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)],1)]}}],null,false,3786941256)})],1)]),_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":"Co-insurance","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Co-insurance","label-for":"gender"}},[_c('v-select',{attrs:{"id":"type_consult","options":_vm.statusOptions,"reduce":function (value) { return value.value; },"label":"name"},model:{value:(_vm.stateData.isCoInsurance),callback:function ($$v) {_vm.$set(_vm.stateData, "isCoInsurance", $$v)},expression:"stateData.isCoInsurance"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,814927402)}),_c('validation-provider',{staticClass:"w-100 ml-2",attrs:{"name":"Co-insurance","rules":_vm.stateData.isCoInsurance ? 'required|decimal' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Co-insurance","label-for":"Co-insurance"}},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"append":"%"}},[_c('b-form-input',{attrs:{"disabled":!_vm.stateData.isCoInsurance},model:{value:(_vm.stateData.coInsurance),callback:function ($$v) {_vm.$set(_vm.stateData, "coInsurance", $$v)},expression:"stateData.coInsurance"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,259993108)})],1),_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('validation-provider',{staticClass:"w-100",attrs:{"name":"Deductible","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Deductible","label-for":"gender"}},[_c('v-select',{attrs:{"id":"type_consult","options":_vm.statusOptions,"reduce":function (value) { return value.value; },"label":"name"},model:{value:(_vm.stateData.isDeductible),callback:function ($$v) {_vm.$set(_vm.stateData, "isDeductible", $$v)},expression:"stateData.isDeductible"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1928960711)}),_c('validation-provider',{staticClass:"w-100 ml-2",attrs:{"name":"deductible","rules":_vm.stateData.isDeductible ? 'required|decimal' : null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"deductible","label-for":"deductible"}},[_c('b-form-input',{attrs:{"disabled":!_vm.stateData.isDeductible},model:{value:(_vm.stateData.deductible),callback:function ($$v) {_vm.$set(_vm.stateData, "deductible", $$v)},expression:"stateData.deductible"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,845733603)})],1)]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"d-flex justify-content-center mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"button","variant":"outline-danger"},on:{"click":_vm.onHide}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","type":"submit","disabled":_vm.loading}},[(!_vm.loading)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("Add"))+" "),(_vm.stateData.isInsurance && _vm.stateData.isCoPayment)?_c('feather-icon',{attrs:{"icon":"PrinterIcon"}}):_vm._e()],1):_c('span',[_c('SpinnerLoading')],1)])],1)],1)])],1)],1),_c('ToastNotification',{ref:"toast"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }