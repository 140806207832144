import { userAxios, apiV2 } from '../index'

const appointmentDelete = async (id) => {
    try {
        return await userAxios.post(`appoiment-actions/delet`, id).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentReOpen = async (appoinment, id) => {
    try {
        return await userAxios.post(`appoiment-actions/open-appointment/${appoinment.id}`, id).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentChangeFacility = async (data) => {
    try {
        return await userAxios.post(`appoiment-actions/change-facility`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentChangeOMA = async (data) => {
    try {
        return await userAxios.post('appoiment-actions/change-oma', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentAssignCuppon = async (data) => {
    try {
        return await userAxios.post(`validate_cupon`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentCancel = async (appoinment, data) => {
    try {
        return await userAxios.put(`office_visit/calcel_appointment/${appoinment}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentRefund = async (appoinment, data) => {
    try {
        return await userAxios.post(`appointment/refund/${appoinment}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentChangePayment = async (appoinment, data) => {
    try {
        return await apiV2.post(`/appointment/insurances/change-to-self-pay-appointment/${appoinment}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentUpdateInsuranceInfo = async (appoinment, data) => {
    try {
        return await apiV2.put(`appointment/insurances/update-insurance-info/${appoinment}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentAddCopay = async (appoinment, data) => {
    try {
        return await apiV2.post(`appointment/insurances/add-copay/${appoinment}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentRefundsV2 = async (appoinment, data) => {
    try {
        return await apiV2.post(`appointment/payments/refunds/${appoinment}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentChangeInsurance = async (appoinment, data) => {
    try {
        return await apiV2.post(`appointment/insurances/change-to-insurance-appointment/${appoinment}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentChangeTypeOfVisit = async (typeVisitId, data) => {
    try {
        return await userAxios.put(`appointments/change-visit-type/${typeVisitId}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentChangeTypeOfVisitOnline = async (typeVisitId) => {
    try {
        return await userAxios.put(`appointments/change-online-visit-type/${typeVisitId}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentPickUpMedication = async (paginate) => {
    try {
        return await userAxios.get(`pick-up-medications/${paginate}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    appointmentDelete,
    appointmentChangeFacility,
    appointmentChangeOMA,
    appointmentAssignCuppon,
    appointmentCancel,
    appointmentRefund,
    appointmentReOpen,
    appointmentChangeTypeOfVisit,
    appointmentChangeTypeOfVisitOnline,
    appointmentPickUpMedication,
    appointmentChangeInsurance,
    appointmentRefundsV2,
    appointmentAddCopay,
    appointmentUpdateInsuranceInfo,
    appointmentChangePayment
}