<template>
  <div>
    <b-modal
      id="modal-add-appointment"
      hide-footer
      hide-header
      size="lg"
      centered
      ignore-enforce-focus-selector="#add-new-consult-sidebar"
      v-model="isAddNewAppointmentActive"
      @hide="onHide"
    >
      <div class="mb-13 mt-2 text-center">
        <h2 class="mb-1">New Appointment</h2>
        <div class="text-muted">
          If you need more info, please check see the
          <router-link to="tutorial" class="fw-bold link-primary">
            tutorials</router-link
          >.
        </div>
        <div v-show="msgAlert.status" class="alert alert-warning">
          <p v-for="mes in msgAlert.msg">
            {{ mes }}
          </p>
        </div>
      </div>
      <PendingPayment
        v-if="hasPendingInsurances"
        :openModal="hasPendingInsurances"
        :insuranceData="insuranceData"
        :appointmentById="appointmentById"
        @closeModalPay="hasPendingInsurances = false"
        @NotificationCheck="notificationCheckType"
        @paymentChecked="hasPendingInsurances = false"
      />
      <validation-observer ref="refFormObserver">
        <b-form @submit.prevent="onSubmit">
          <div class="m-2">
            <validation-provider
              #default="{ errors }"
              name="Patient"
              rules="required"
            >
              <b-form-group :label="$t('Patient')" label-for="patient">
                <b-form-input
                  disabled
                  :value="`${userAppointment.name} ${userAppointment.lastname}`"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <div class="d-flex justify-content-between align-items-center">
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="email"
                class="w-100"
              >
                <b-form-group label="Email" label-for="email">
                  <b-form-input v-model="stateData.email" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <validation-provider
                #default="{ errors }"
                name="Gender"
                rules=""
                class="w-100 ml-2"
              >
                <b-form-group label="Gender" label-for="gender">
                  <v-select
                    id="type_consult"
                    v-model="stateData.gender"
                    :options="genderOptions"
                    :reduce="(value) => value.value"
                    label="name"
                  >
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </div>

            <validation-provider
              #default="{ errors }"
              name="Pick up medication"
              rules=""
              class="w-100"
            >
              <b-form-group label="Pick up medication" label-for="pick-up">
                <v-select
                  id="pick-up"
                  v-model="stateData.pick_up_medication_id"
                  :options="medicationsOptions"
                  :reduce="(value) => value.id"
                  label="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <div class="d-flex justify-content-between align-items-center">
              <validation-provider
                #default="{ errors }"
                name="Type Visit"
                rules="required"
                class="w-100"
              >
                <b-form-group
                  :label="$t('Types of visits')"
                  label-for="type visit"
                >
                  <v-select
                    id="type visit"
                    v-model="stateData.type_visits_id"
                    :options="optionsTypeVisit"
                    @search="searchTypeVisit"
                    :reduce="(option) => option.id"
                    :clearable="false"
                    label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="{ errors }"
                name="Visit Reason"
                rules="required"
                class="w-100 ml-2"
              >
                <b-form-group label-for="type_consult">
                  <div class="d-flex justify-content-between">
                    <label for="type_consult">{{ $t("Visit Reason") }}</label>
                  </div>
                  <v-select
                    id="type_consult"
                    v-model="stateData.type_consultations_id"
                    :options="optionsTypeConsult"
                    @search="searchTypeConsult"
                    :reduce="(option) => option.id"
                    :clearable="false"
                    label="name"
                  >
                    <template #no-options="{ search, searching }">
                      <NewItemSelect
                        v-if="searching"
                        :search="search"
                        @onSaveVisitReason="onSaveVisitReason(search)"
                      />
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </div>
            <div v-if="!isInsuranceMedicalCenter">
              <div class="d-flex justify-content-between align-items-center">
                <div class="me-5">
                  <label class="fs-6 fw-semobold">Scan QR</label>
                </div>
                <b-form-checkbox
                  v-model="checkCodeQr"
                  name="check-button"
                  switch
                  @change="onClickScanQr($event)"
                  :disabled="stateData.isInsurance"
                >
                </b-form-checkbox>
              </div>
              <validation-provider
                v-show="checkCodeQr"
                #default="{ errors }"
                name="Qr"
              >
                <scanQr
                  v-if="checkCodeQrModal"
                  :userId="userAppointment.id"
                  typeQr="cupon"
                  @closeModalPay="closeModalMembership"
                  @successModalQr="successModalQr"
                  @notificationCheck="notificationCheckType"
                />
                <!-- <b-form-group label="Qr" label-for="Qr">
                  <b-form-input
                    id="Qr"
                    v-model="stateData.codeQr"
                    trim
                    placeholder="Code qr"
                  />
                </b-form-group> -->

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div>
              <div
                class="d-flex justify-content-between align-items-center my-1"
              >
                <div class="me-5">
                  <label class="fs-6 fw-semobold">Insurance</label>
                </div>
                <b-form-checkbox
                  v-model="stateData.isInsurance"
                  @change="changeInsurance"
                  :disabled="checkCodeQr"
                  name="check-button"
                  switch
                >
                </b-form-checkbox>
              </div>
              <div v-if="stateData.isInsurance">
                <div class="d-flex justify-content-between align-items-center">
                  <validation-provider
                    #default="{ errors }"
                    name="Insurance Type"
                    rules="required"
                    class="w-100"
                  >
                    <b-form-group
                      label="Insurance Type"
                      label-for="insurance-type"
                    >
                      <v-select
                        id="insurance-type"
                        v-model="stateData.insurance_type_id"
                        :options="insuranceOptions"
                        @search="searchTypeInsurance"
                        :reduce="(value) => value.id"
                        label="name"
                      >
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <validation-provider
                    #default="{ errors }"
                    name="Co-payment"
                    :rules="required"
                    class="w-100"
                  >
                    <b-form-group label="Co-payment" label-for="is-copay">
                      <v-select
                        id="is-copay"
                        v-model="stateData.isCoPayment"
                        :options="statusOptions"
                        :reduce="(value) => value.value"
                        label="name"
                      >
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex w-50">
                    <validation-provider
                      #default="{ errors }"
                      name="Credit Card"
                      :rules="
                        stateData.isCoPayment ? 'required|decimal:3' : null
                      "
                    >
                      <b-form-group label="Credit Card" label-for="cc">
                        <b-form-input
                          name="cc"
                          :disabled="
                            !stateData.isCoPayment ||
                            isAddNewRecipeInsurancePaymentActive
                          "
                          v-model="stateData.copay.payment.cc.value"
                          @change="changeCopaymentTotal"
                        />
                      </b-form-group>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <!-- clover id cc -->
                  </div>
                  <div class="d-flex w-100 ml-2">
                    <validation-provider
                      #default="{ errors }"
                      name="CC payment id"
                      :rules="
                        stateData.isCoPayment &&
                        stateData.copay.payment.cc.value != 0
                          ? 'required|min:4'
                          : null
                      "
                    >
                      <b-form-group label="CC payment Id" label-for="cash">
                        <b-form-input
                          name="Transaction Clover Id"
                          v-model="stateData.copay.payment.cc.transaction_id"
                          :state="errors.length > 0 ? false : null"
                          :disabled="
                            !stateData.isCoPayment ||
                            isAddNewRecipeInsurancePaymentActive ||
                            stateData.copay.payment.cc.value == 0
                          "
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>
                  <div class="d-flex w-50 ml-2">
                    <validation-provider
                      #default="{ errors }"
                      name="Cash"
                      :rules="
                        stateData.isCoPayment ? 'required|decimal:3' : null
                      "
                    >
                      <b-form-group label="Cash" label-for="cash">
                        <b-input-group>
                          <b-form-input
                            name="cash"
                            :disabled="
                              !stateData.isCoPayment ||
                              isAddNewRecipeInsurancePaymentActive
                            "
                            v-model="stateData.copay.payment.cash.value"
                            @change="changeCopaymentTotal"
                          />
                        </b-input-group>
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <!-- clover id cash -->
                  <div class="d-flex w-100 ml-2">
                    <validation-provider
                      #default="{ errors }"
                      name="Cash Payment Id"
                      :rules="
                        stateData.isCoPayment &&
                        stateData.copay.payment.cash.value != 0
                          ? 'required|min:4'
                          : null
                      "
                    >
                      <b-form-group label="Cash Payment Id" label-for="cash">
                        <b-input-group>
                          <b-form-input
                            name="Transaction Clover Id"
                            v-model="
                              stateData.copay.payment.cash.transaction_id
                            "
                            :state="errors.length > 0 ? false : null"
                            :disabled="
                              !stateData.isCoPayment ||
                              isAddNewRecipeInsurancePaymentActive ||
                              stateData.copay.payment.cash.value == 0
                            "
                          />
                          <!-- <b-input-group-append>
                            <b-button :disabled="!stateData.isCoPayment" @click="createRecipeInsurancePayment"
                              variant="success">
                              <feather-icon icon="PrinterIcon" />
                            </b-button>
                          </b-input-group-append> -->
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-input-group>
                      </b-form-group>
                    </validation-provider>
                  </div>
                </div>
                <!-- Co insurance -->
                <div class="d-flex justify-content-between align-items-center">
                  <validation-provider
                    #default="{ errors }"
                    name="Co-insurance"
                    rules="required"
                    class="w-100"
                  >
                    <b-form-group label="Co-insurance" label-for="gender">
                      <v-select
                        id="type_consult"
                        v-model="stateData.isCoInsurance"
                        :options="statusOptions"
                        :reduce="(value) => value.value"
                        label="name"
                      >
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="Co-insurance"
                    :rules="stateData.isCoInsurance ? 'required|decimal' : null"
                    class="w-100 ml-2"
                  >
                    <b-form-group label="Co-insurance" label-for="Co-insurance">
                      <b-input-group append="%" class="input-group-merge">
                        <b-form-input
                          :disabled="!stateData.isCoInsurance"
                          v-model="stateData.coInsurance"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <validation-provider
                    #default="{ errors }"
                    name="Deductible"
                    rules="required"
                    class="w-100"
                  >
                    <b-form-group label="Deductible" label-for="gender">
                      <v-select
                        id="type_consult"
                        v-model="stateData.isDeductible"
                        :options="statusOptions"
                        :reduce="(value) => value.value"
                        label="name"
                      >
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                  <validation-provider
                    #default="{ errors }"
                    name="deductible"
                    :rules="stateData.isDeductible ? 'required|decimal' : null"
                    class="w-100 ml-2"
                  >
                    <b-form-group label="deductible" label-for="deductible">
                      <b-form-input
                        :disabled="!stateData.isDeductible"
                        v-model="stateData.deductible"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center mt-2">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                class="mr-1"
                variant="outline-danger"
                @click="onHide"
              >
                {{ $t("Cancel") }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="submit"
                :disabled="loading"
              >
                <span v-if="!loading">
                  {{ $t("Add") }}
                  <feather-icon
                    icon="PrinterIcon"
                    v-if="stateData.isInsurance"
                  />
                </span>
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastNotification from "@/components/ToastNotification";
import { notificationCheck } from "@/mixins/NotificationSetup";
import NotificationMixin from "@/mixins/NotificationMixin";

import SpinnerLoading from "@/components/SpinnerLoading";
import NewItemSelect from "@/components/NewItemSelect";
import scanQr from "@/components/scanQr.vue";

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import router from "@/router";

import { required, decimal } from "@validations";
import { onMounted, ref, watch } from "@vue/composition-api";

import axiosAppointment from "@/services/appointment";
import axiosAC from "@/services/appointment/actions";
import axiosRcm from "@/services/rcm";

import axiosTV from "@/services/admin/consults/visits";
import axiosC from "@/services/admin/consults/consults";
import PendingPayment from "@/views/patients/patients-actions/PendingPayment.vue";

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    PendingPayment,
    SpinnerLoading,
    vSelect,
    flatPickr,
    ToastNotification,

    NewItemSelect,
    scanQr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [NotificationMixin],
  directives: {
    Ripple,
  },
  props: {
    userAppointment: {
      type: Object,
      required: true,
    },
    isInsuranceMedicalCenter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      decimal,
    };
  },
  setup(props, { emit }) {
    const hasPendingInsurances = ref(false);
    const appointmentById = ref(props.userAppointment.id);
    const insuranceData = ref({
      notes: [],
      total_pending_payment_amount: 0,
    });
    const typeInsuranceOptions = [
      { label: "Deductible", id: "deductible" },
      { label: "copay", id: "copay" },
      { label: "Co Insurance", id: "coinsurance" },
    ];
    const formInsurance = ref({
      insurance_id: 0,
      type_insurance: "",
      total_pending: 0,
      cash: {
        value: 0,
        transaction_id: null,
      },
      cc: {
        value: 0,
        transaction_id: null,
      },
      is_split_pay: false,
    });
    axiosAppointment
      .validatePendingInsurances(props.userAppointment.id)
      .then(({ registro }) => {
        if (!registro) return;
        insuranceData.value = registro;
        hasPendingInsurances.value = true;
      });

    async function onPayingInsurance() {
      const check = await refFormInsurance.value.validate();
      if (!check) return;
      if (
        +formInsurance.value.cash.value + +formInsurance.value.cc.value !=
        insuranceData.value.total_pending_payment_amount
      )
        return notificationCheckType({
          status: "ERROR",
          message: "The values doesn't match with the total pending!",
        });

      loading.value = true;
      formInsurance.value.insurance_id = insuranceData.value.insurance;
      formInsurance.value.total_pending =
        insuranceData.value.total_pending_payment_amount;
      formInsurance.value.is_split_pay =
        formInsurance.value.cc.value && formInsurance.value.cash.value;

      axiosAppointment
        .payPendingInsurances(formInsurance.value)
        .then((res) => {
          notificationCheckType(res);
          if (res.status != "ERROR" && res.status != "VALIDATION")
            hasPendingInsurances.value = false;
          loading.value = false;
        })
        .catch(() => {
          loading.value = false;
          notificationCheckType({
            status: "ERROR",
            message: "Unexpected error, please contact with an administrator!",
          });
        });
    }
    const genderOptions = [
      { name: "Male", value: "male" },
      { name: "Female", value: "female" },
    ];
    const firstCC = ref(false);
    const statusOptions = [
      { name: "Yes", value: true },
      { name: "No", value: false },
    ];
    const insuranceOptions = ref([]);
    const stateDataTem = {
      type_visits_id: null,
      type_consultations_id: null,
      email: props.userAppointment.email || null,
      gender: props.userAppointment.gender || null,
      scanQr: null,
      isInsurance: false,
      isCoPayment: null,
      coPayment: null,
      isCoInsurance: null,
      coInsurance: null,
      insurance_type_id: null,
      isDeductible: null,
      deductible: null,
      copay: {
        payment: {
          cc: {
            value: 0,
            transaction_id: "",
          },
          cash: {
            value: 0,
            transaction_id: "",
          },
        },
      },
      pick_up_medication_id: null,
    };
    const stateData = ref(JSON.parse(JSON.stringify(stateDataTem)));
    const toast = ref(null);
    watch(firstCC, () => {
      if (firstCC.value == true) toast.value.ccNotification();
    });
    const isAddNewAppointmentActive = true;
    const onHide = () => {
      emit("closeModalAppoinment", false);
    };

    const checkCodeQr = ref(false);
    const checkCodeQrModal = ref(false);
    const onClickScanQr = (event) => {
      checkCodeQrModal.value = event;
    };

    const closeModalMembership = (data) => {
      checkCodeQrModal.value = data;
    };

    const changeCopaymentTotal = () => {
      const { copay } = stateData.value;
      if (copay.payment.cc.value) firstCC.value = true;
      const total =
        parseFloat(copay.payment.cc.value) +
        parseFloat(copay.payment.cash.value);
      stateData.value.convenience_fee = (copay.payment.cc.value * 3) / 100;
      stateData.value.total = total + stateData.value.convenience_fee;
      stateData.value.coPayment = total;
    };

    const successModalQr = ({ registro, status, qr }) => {
      if (status != "ERROR") {
        stateData.value.scanQr = qr ? qr : registro;
        checkCodeQr.value = true;
        return;
      }
      checkCodeQr.value = false;
    };

    const notificationCheckType = (res) => {
      const { type, message } = notificationCheck(res);
      toast.value[type](message);
    };

    const changeInsurance = () => {
      stateData.value.coPayment = null;
      stateData.value.convenience_fee = null;
      stateData.value.total = null;
      stateData.value.isCoPayment = false;
      stateData.value.coInsurance = null;
      stateData.value.isCoInsurance = false;
      stateData.value.deductible = null;
      stateData.value.isDeductible = false;
    };

    const onSaveVisitReason = (name) => {
      const data = {
        name,
        status: true,
      };
      axiosC.consultCreate(data).then(({ registro }) => {
        stateData.value.type_consultations_id = registro.id;
        optionsTypeConsult.value = [registro];
      });
    };

    const isAddNewRecipeInsurancePaymentActive = ref(false);
    const createRecipeInsurancePayment = () => {
      isAddNewRecipeInsurancePaymentActive.value = true;
      const data = {
        ...props.userAppointment,
        payment: stateData.value.coPayment,
        total: stateData.value.total,
        convenience_fee: stateData.value.convenience_fee,
      };
      const bloblstateData = bufferABase64(
        new TextEncoder().encode(JSON.stringify(data))
      );
      const routeData = router.resolve({
        name: "recipe-insurance-payment",
        query: { data: bloblstateData },
      });
      window.open(routeData.href, "_blank");
    };
    const bufferABase64 = (buffer) =>
      btoa(String.fromCharCode(...new Uint8Array(buffer)));

    const optionsTypeVisit = ref([]);
    const optionsTypeConsult = ref([]);
    const medicationsOptions = ref([]);

    const clearTimeoutBuscador = ref("");
    const msgAlert = ref({
      status: false,
      msg: null,
    });

    onMounted(() => {
      loadTreatmentPending();
      loadTypeVisit();
      loadTypeConsult();
      loadPickUpMedication();
      if (props.isInsuranceMedicalCenter) {
        stateData.value.isInsurance = props.isInsuranceMedicalCenter;
      }
    });

    const loadPickUpMedication = () => {
      axiosAC.appointmentPickUpMedication(15).then(({ data }) => {
        medicationsOptions.value = data;
      });
    };

    const loadTypeVisit = () => {
      axiosTV.visitListActive(15).then(({ data }) => {
        optionsTypeVisit.value = data;
      });
    };
    const loadTypeConsult = () => {
      axiosC.consultList(15).then(({ data }) => {
        optionsTypeConsult.value = data;
      });
    };
    const searchTypeVisit = (name, loading) => {
      if (name.length) {
        const datos = {
          name,
        };
        loading(true);
        clearTimeout(clearTimeoutBuscador.value);
        clearTimeoutBuscador.value = setTimeout(() => {
          axiosTV
            .visitListActiveFilter(10, datos)
            .then(({ registro: { data } }) => {
              loading(false);
              optionsTypeVisit.value = data;
            });
        }, 400);
      }
    };
    const searchTypeConsult = (name, loading) => {
      if (name.length) {
        const datos = {
          name,
        };
        loading(true);
        clearTimeout(clearTimeoutBuscador.value);
        clearTimeoutBuscador.value = setTimeout(() => {
          axiosC.consultFilter(10, datos).then(({ data }) => {
            loading(false);
            optionsTypeConsult.value = data;
          });
        }, 400);
      }
    };
    const searchTypeInsurance = (name, loading) => {
      if (name.length) {
        const datos = {
          name,
        };
        loading(true);
        clearTimeout(clearTimeoutBuscador.value);
        clearTimeoutBuscador.value = setTimeout(() => {
          axiosRcm
            .searchTypeInsurance(25, datos)
            .then(({ registro: { data } }) => {
              loading(false);
              insuranceOptions.value = data;
            });
        }, 400);
      }
    };
    axiosRcm.listInsuranceType(25).then(({ registro: { data } }) => {
      insuranceOptions.value = data;
    });

    const loadTreatmentPending = () => {
      axiosAppointment
        .appointmentPendingTreatment(props.userAppointment.id)
        .then(({ status, registro, message }) => {
          if (registro.length) {
            msgAlert.value = {
              status: true,
              msg: registro,
            };
          }
        });
    };

    const loading = ref(false);
    const refFormObserver = ref(null);
    const refFormInsurance = ref(null);
    const onSubmit = async () => {
      const check = await refFormObserver.value.validate();
      if (check) {
        loading.value = true;

        const datos = {
          user_type: "established",
          patients_id: props.userAppointment.id,
          ...stateData.value,
        };

        const methodAxios = props.isInsuranceMedicalCenter
          ? "appointmentCreateInsurance"
          : "appointmentCreate";

        axiosAppointment[methodAxios](datos)
          .then((res) => {
            const { type, message } = notificationCheck(res);
            emit("createAppointment", {
              type,
              message,
            });

            if (
              type != "danger" &&
              stateData.value.isInsurance &&
              stateData.value.isCoPayment
            )
              return createRecipeInsurancePayment();
          })
          .catch(() => {
            loading.value = false;
            const { type, message } = notificationCheck(res);
            emit("createAppointment", {
              type,
              message,
            });
          });
      }
    };

    const config = {
      altFormat: "F j, Y",
      dateFormat: "Y-m-d",
      static: true,
    };
    return {
      insuranceData,
      hasPendingInsurances,
      genderOptions,
      statusOptions,
      insuranceOptions,
      loading,
      stateData,
      searchTypeInsurance,
      optionsTypeVisit,
      optionsTypeConsult,
      toast,
      notificationCheckType,
      onClickScanQr,
      checkCodeQr,
      closeModalMembership,
      successModalQr,
      checkCodeQrModal,
      changeInsurance,
      isAddNewRecipeInsurancePaymentActive,
      createRecipeInsurancePayment,
      onHide,
      appointmentById,
      isAddNewAppointmentActive,
      changeCopaymentTotal,
      onSaveVisitReason,
      searchTypeConsult,
      searchTypeVisit,
      refFormObserver,
      refFormInsurance,
      onSubmit,
      config,
      medicationsOptions,
      loadTreatmentPending,
      msgAlert,
      typeInsuranceOptions,
      formInsurance,
      onPayingInsurance,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.button-custom {
  padding: 5px !important;
}
</style>
