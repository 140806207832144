<template>
  <div>
    <b-modal id="modal-add-new-patient" hide-footer hide-header size="lg" centered
      ignore-enforce-focus-selector="#add-new-consult-sidebar" v-model="conditionAddNewPatient" @hide="onHide">
      <div class="mb-13 mt-2 text-center">
        <h2 class="mb-1">New Patient</h2>
        <div class="text-muted">
          If you need more info, please check see the
          <router-link to="tutorial" class="fw-bold link-primary">
            tutorials</router-link>.
        </div>
      </div>
      <validation-observer ref="refFormObserver">
        <b-form @submit.prevent="onSubmit">
          <div class="mx-2">
            <div class="d-flex justify-content-between align-items-center">
              <validation-provider #default="{ errors }" name="First Name" rules="required" class="w-100">
                <b-form-group :label="$t('Form.Name')" label-for="name">
                  <b-form-input id="name" v-model="stateData.name" trim placeholder="First Name" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <!-- Full Name -->
              <validation-provider #default="{ errors }" name="Last Name" rules="required" class="w-100 ml-2">
                <b-form-group :label="$t('Form.Last Name')" label-for="lastname">
                  <b-form-input id="lastname" v-model="stateData.lastname" trim placeholder="Last Name" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <!-- Phone -->
              <validation-provider ref="rulesContact" #default="{ errors }" name="Phone" rules="required|max:14"
                class="w-100">
                <b-form-group :label="$t('Form.Phone')" label-for="contact">
                  <b-form-input id="contact" v-model="formatPhone" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="DOB" rules="required" class="w-100 ml-2">
                <b-form-group label="DOB" label-for="date-birth">
                  <div class="d-flex flex-column">
                    <flat-pickr v-model="stateData.date_birth" :config="config" class="form-control"
                      placeholder="MM-DD-YYYY" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </div>
                </b-form-group>
              </validation-provider>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <validation-provider #default="{ errors }" name="Email" rules="email" class="w-100">
                <b-form-group label="Email" label-for="email">
                  <b-form-input v-model="stateData.email" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <validation-provider #default="{ errors }" name="Gender" rules="" class="w-100 ml-2">
                <b-form-group label="Gender" label-for="gender">
                  <v-select id="type_consult" v-model="stateData.gender" :options="genderOptions"
                    :reduce="(value) => value.value" label="name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </div>
            <validation-provider #default="{ errors }" name="Pick up medication" rules="" class="w-100">
              <b-form-group label="Pick up medication" label-for="pick-up">
                <v-select id="pick-up" v-model="stateData.pick_up_medication_id" :options="medicationsOptions"
                  :reduce="(value) => value.id" label="name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <div v-if="!isOnlyCreatePatient">
              <div class="d-flex justify-content-between align-items-center">
                <validation-provider #default="{ errors }" name="Type Visit" rules="required" class="w-100">
                  <b-form-group :label="$t('Types of visits')" label-for="type visit">
                    <v-select id="type visit" v-model="stateData.type_visits_id" :options="optionsTypeVisit"
                      @search="searchTypeVisit" :reduce="(option) => option.id" :clearable="false" label="name" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <validation-provider #default="{ errors }" name="Visit Reason" rules="required" class="w-100 ml-2">
                  <b-form-group :label="$t('Visit Reason')" label-for="visit_reason">
                    <v-select id="type_consult" v-model="stateData.type_consultations_id" :options="optionsTypeConsult"
                      @search="searchTypeConsult" :reduce="(option) => option.id" :clearable="false" label="name">
                      <template #no-options="{ search, searching }">
                        <NewItemSelect v-if="searching" :search="search"
                          @onSaveVisitReason="onSaveVisitReason(search)" />
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </div>
              <div v-if="!isInsuranceMedicalCenter && false">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="me-5">
                    <label class="fs-6 fw-semobold">Scan QR</label>
                  </div>
                  <b-form-checkbox v-model="checkCodeQr" name="check-button" switch @change="onClickScanQr($event)"
                    :disabled="stateData.isInsurance">
                  </b-form-checkbox>
                </div>
                <validation-provider v-show="checkCodeQr" #default="{ errors }" name="Qr">
                  <AppointmentMembership v-if="checkCodeQrModal" @closeModalPay="closeModalMembership"
                    @successModalQr="successModalCupon" @notificationCheck="notificationCheckType" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
              <div v-if="!isInsuranceMedicalCenter">
                <div class="d-flex justify-content-between align-items-center my-1">
                  <div class="me-5">
                    <label class="fs-6 fw-semobold">Scan Cupon</label>
                  </div>
                  <b-form-checkbox v-model="checkCupon" name="check-button" switch @change="onClickScanCupon($event)">
                  </b-form-checkbox>
                </div>
                <validation-provider v-show="checkCupon" #default="{ errors }" name="Cupon">
                  <AppointmentMembership v-if="checkCuponModal" typeQr="cupon" @successModalQr="successModalCupon"
                    @closeModalPay="closeModalCupon" @notificationCheck="notificationCheckType" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <b-input-group :label="$t('Visit Reason')" label-for="visit_reason" v-if="cupon">
                  <b-form-input v-model="cupon" disabled />
                  <template #append>
                    <b-button variant="outline-danger" @click="noCupon">X</b-button>
                  </template>
                </b-input-group>
              </div>
              <div>
                <div class="d-flex justify-content-between align-items-center my-1">
                  <div class="me-5">
                    <label class="fs-6 fw-semobold">Insurance</label>
                  </div>
                  <b-form-checkbox v-model="stateData.isInsurance" @change="changeInsurance" :disabled="checkCodeQr"
                    name="check-button" switch>
                  </b-form-checkbox>
                </div>
                <div v-if="stateData.isInsurance">
                  <div class="d-flex justify-content-between align-items-center">
                    <validation-provider #default="{ errors }" name="Insurance Type" rules="required" class="w-100">
                      <b-form-group label="Insurance Type" label-for="insurance-type">
                        <v-select id="insurance-type" v-model="stateData.insurance_type_id" :options="insuranceOptions"
                          @search="searchTypeInsurance" :reduce="(value) => value.id" label="name">
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>
                  <div class="d-flex justify-content-between align-items-center">
                    <validation-provider #default="{ errors }" name="Co-payment" :rules="required" class="w-100">
                      <b-form-group label="Co-payment" label-for="is-copay">
                        <v-select id="is-copay" v-model="stateData.isCoPayment" :options="statusOptions"
                          :reduce="(value) => value.value" label="name">
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex w-50">
                      <validation-provider #default="{ errors }" name="Credit Card" :rules="stateData.isCoPayment ? 'required|decimal:3' : null
                        ">
                        <b-form-group label="Credit Card" label-for="cc">
                          <b-form-input name="cc" :disabled="!stateData.isCoPayment ||
                            isAddNewRecipeInsurancePaymentActive
                            " v-model="stateData.copay.payment.cc.value" @change="changeCopaymentTotal" />
                        </b-form-group>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <!-- clover id cc -->
                    </div>
                    <div class="d-flex w-100 ml-2">
                      <validation-provider #default="{ errors }" name="CC payment id"
                        :rules="stateData.isCoPayment && stateData.copay.payment.cc.value != 0 ? 'required|min:4' : null">
                        <b-form-group label="CC payment Id" label-for="cash">
                          <b-form-input name="Transaction Clover Id" v-model="stateData.copay.payment.cc.transaction_id"
                            :state="errors.length > 0 ? false : null"
                            :disabled="!stateData.isCoPayment || isAddNewRecipeInsurancePaymentActive || stateData.copay.payment.cc.value == 0" />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </div>
                    <div class="d-flex w-50 ml-2">
                      <validation-provider #default="{ errors }" name="Cash" :rules="stateData.isCoPayment ? 'required|decimal:3' : null
                        ">
                        <b-form-group label="Cash" label-for="cash">
                          <b-input-group>
                            <b-form-input name="cash" :disabled="!stateData.isCoPayment ||
                              isAddNewRecipeInsurancePaymentActive
                              " v-model="stateData.copay.payment.cash.value" @change="changeCopaymentTotal" />
                          </b-input-group>
                        </b-form-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                    <!-- clover id cash -->
                    <div class="d-flex w-100 ml-2">
                      <validation-provider #default="{ errors }" name="Cash Payment Id"
                        :rules="stateData.isCoPayment && stateData.copay.payment.cash.value != 0 ? 'required|min:4' : null">
                        <b-form-group label="Cash Payment Id" label-for="cash">
                          <b-input-group>
                            <b-form-input name="Transaction Clover Id"
                              v-model="stateData.copay.payment.cash.transaction_id"
                              :state="errors.length > 0 ? false : null"
                              :disabled="!stateData.isCoPayment || isAddNewRecipeInsurancePaymentActive || stateData.copay.payment.cash.value == 0" />
                            <!-- <b-input-group-append>
                              <b-button :disabled="!stateData.isCoPayment" @click="createRecipeInsurancePayment"
                                variant="success">
                                <feather-icon icon="PrinterIcon" />
                              </b-button>
                            </b-input-group-append> -->
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-input-group>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center">
                    <validation-provider #default="{ errors }" name="Co-insurance" rules="required" class="w-100">
                      <b-form-group label="Co-insurance" label-for="gender">
                        <v-select id="type_consult" v-model="stateData.isCoInsurance" :options="statusOptions"
                          :reduce="(value) => value.value" label="name">
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                    <validation-provider #default="{ errors }" name="Co-insurance" :rules="stateData.isCoInsurance ? 'required|decimal' : null
                      " class="w-100 ml-2">
                      <b-form-group label="Co-insurance" label-for="Co-insurance">
                        <b-input-group append="%" class="input-group-merge">
                          <b-form-input :disabled="!stateData.isCoInsurance" v-model="stateData.coInsurance" />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>
                  <div class="d-flex justify-content-between align-items-center">
                    <validation-provider #default="{ errors }" name="Deductible" rules="required" class="w-100">
                      <b-form-group label="Deductible" label-for="gender">
                        <v-select id="type_consult" v-model="stateData.isDeductible" :options="statusOptions"
                          :reduce="(value) => value.value" label="name">
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                    <validation-provider #default="{ errors }" name="deductible" :rules="stateData.isDeductible ? 'required|decimal' : null
                      " class="w-100 ml-2">
                      <b-form-group label="deductible" label-for="deductible">
                        <b-form-input :disabled="!stateData.isDeductible" v-model="stateData.deductible" />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center mt-2">
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" class="mr-1" variant="outline-danger"
                @click="onHide">
                {{ $t("Cancel") }}
              </b-button>
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" type="submit" :disabled="loading">
                <span v-if="!loading">
                  {{ $t("Add") }}
                  <feather-icon icon="PrinterIcon" v-if="stateData.isInsurance && stateData.isCoPayment" />
                </span>
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import router from "@/router";

import { required } from "@validations";
import { computed, onMounted, ref, watch } from "@vue/composition-api";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import NewItemSelect from "@/components/NewItemSelect";
import { notificationCheck } from "@/mixins/NotificationSetup";
import NotificationMixin from "@/mixins/NotificationMixin";
import AppointmentMembership from "@/components/scanQr.vue";

import axiosPatient from "@/services/patient";
import axiosRcm from "@/services/rcm";
import axiosTV from "@/services/admin/consults/visits";
import axiosC from "@/services/admin/consults/consults";
import axiosA from "@/services/appointment/actions";

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BButton,
    ToastNotification,
    NewItemSelect,
    AppointmentMembership,
    SpinnerLoading,
    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [NotificationMixin],
  directives: {
    Ripple,
  },
  props: {
    isScanQrPatient: {
      type: String,
      default: "",
    },
    isOnlyCreatePatient: {
      type: Boolean,
      default: false,
    },
    isInsuranceMedicalCenter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const genderOptions = [
      { name: "Male", value: "male" },
      { name: "Female", value: "female" },
    ];
    const firstCC = ref(false)
    const statusOptions = [
      { name: "Yes", value: true },
      { name: "No", value: false },
    ];
    const insuranceOptions = ref([]);
    const conditionAddNewPatient = true;
    const stateDataTem = {
      name: null,
      lastname: null,
      type_visits_id: null,
      type_consultations_id: null,
      phone: "",
      date_birth: "",
      insurance_type_id: null,
      email: null,
      gender: null,
      scanQr: null,
      isInsurance: false,
      isCoPayment: null,
      coPayment: null,
      copay: {
        payment: {
          cc: {
            value: 0,
            transaction_id: ""
          },
          cash: {
            value: 0,
            transaction_id: ""
          },
        }
      },
      isCoInsurance: null,
      coInsurance: null,
      isDeductible: null,
      deductible: null,
      pick_up_medication_id: null,
    };
    const stateData = ref(JSON.parse(JSON.stringify(stateDataTem)));

    const resetstateData = () => {
      stateData.value = JSON.parse(JSON.stringify(stateDataTem));
    };

    const onHide = () => {
      emit("closeModalPatient", false);
    };

    const checkCodeQr = ref(false);
    const checkCupon = ref(false);
    const inputScan = ref(null);
    const toast = ref(null);
    watch(firstCC, () => {
      if (firstCC.value == true) toast.value.ccNotification()
    })
    const scanPromotion = () => {
      if (checkCodeQr.value) {
        stateData.value.codeQr = null;
      }
      checkCodeQr.value = !checkCodeQr.value;
    };

    const formatPhone = computed({
      get() {
        let { phone } = stateData.value;
        phone = phone.toString();
        const match = phone
          .replace(/\D+/g, "")
          .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        return match;
      },
      set(val) {
        stateData.value.phone = val;
      },
    });

    const clearTimeoutBuscador = ref("");
    const searchTypeVisit = (name, loading) => {
      if (name.length) {
        const datos = {
          name,
        };
        loading(true);
        clearTimeout(clearTimeoutBuscador.value);
        clearTimeoutBuscador.value = setTimeout(() => {
          axiosTV
            .visitListActiveFilter(10, datos)
            .then(({ registro: { data } }) => {
              loading(false);
              optionsTypeVisit.value = data;
            });
        }, 400);
      }
    };
    const searchTypeInsurance = (name, loading) => {
      if (name.length) {
        const datos = {
          name,
        };
        loading(true);
        clearTimeout(clearTimeoutBuscador.value);
        clearTimeoutBuscador.value = setTimeout(() => {
          axiosRcm
            .searchTypeInsurance(25, datos)
            .then(({ registro: { data } }) => {
              loading(false);
              insuranceOptions.value = data;
            });
        }, 400);
      }
    };
    axiosRcm.listInsuranceType(25).then(({ registro: { data } }) => {
      insuranceOptions.value = data;
    });
    const closeModalMembership = (data) => {
      // checkCodeQrModal.value = data;
    };
    const closeModalCupon = (data) => {
      checkCuponModal.value = data;
    };
    // const successModalQr = ({ registro, status, qr }) => {
    //   console.log(status);
    //   if (status === "ERROR") {
    //     checkCodeQr.value = false;
    //   } else if (status === "VALIDATION") {
    //     checkCodeQr.value = false;
    //   } else {
    //     stateData.value.scanQr = qr;
    //     checkCodeQr.value = true;
    //   }
    // };
    const cupon = ref("")
    const successModalCupon = ({ registro, status, qr }) => {
      if (status != "ERROR") {
        stateData.value.scanQr = qr ? qr : registro;
        cupon.value = qr
        checkCupon.value = false;
      }
    };
    const notificationCheckType = (res) => {
      const { type, message } = notificationCheck(res);
      toast.value[type](message);
    };
    const searchTypeConsult = (name, loading) => {
      if (name.length) {
        const datos = {
          name,
        };
        loading(true);
        clearTimeout(clearTimeoutBuscador.value);
        clearTimeoutBuscador.value = setTimeout(() => {
          axiosC.consultFilter(10, datos).then(({ data }) => {
            loading(false);
            optionsTypeConsult.value = data;
          });
        }, 400);
      }
    };

    const optionsTypeVisit = ref([]);
    const optionsTypeConsult = ref([]);
    const medicationsOptions = ref([]);

    onMounted(() => {
      loadTypeVisit();
      loadTypeConsult();
      loadPickUpMedication();
      if (props.isScanQrPatient !== null) {
        stateData.value.scanQr = props.isScanQrPatient;
        checkCodeQr.value = true;
      }
      if (props.isInsuranceMedicalCenter) {
        stateData.value.isInsurance = props.isInsuranceMedicalCenter;
      }
    });

    const loadPickUpMedication = () => {
      axiosA.appointmentPickUpMedication(15).then(({ data }) => {
        medicationsOptions.value = data;
      });
    };

    const loadTypeVisit = () => {
      axiosTV.visitListActive(15).then(({ data }) => {
        optionsTypeVisit.value = data;
      });
    };
    const loadTypeConsult = () => {
      axiosC.consultList(15).then(({ data }) => {
        optionsTypeConsult.value = data;
      });
    };

    const checkCodeQrModal = ref(false);
    const onClickScanQr = (event) => {
      checkCodeQrModal.value = event;
    };

    const noCupon = () => {
      stateData.value.scanQr = null;
      cupon.value = "";
    };

    const checkCuponModal = ref(false);
    const onClickScanCupon = (event) => {
      checkCuponModal.value = event;
    };

    const onSaveVisitReason = (name) => {
      const data = {
        name,
        status: true,
      };
      axiosC.consultCreate(data).then(({ registro }) => {
        stateData.value.type_consultations_id = registro.id;
        optionsTypeConsult.value = [registro];
      });
    };

    const changeInsurance = () => {
      stateData.value.coPayment = null;
      stateData.value.total = null;
      stateData.value.isCoPayment = false;
      stateData.value.coInsurance = null;
      stateData.value.isCoInsurance = false;
      stateData.value.deductible = null;
      stateData.value.isDeductible = false;
    };

    const changeCopaymentTotal = () => {
      const { copay } = stateData.value;
      if (copay.payment.cc.value) firstCC.value = true
      const total = parseFloat(copay.payment.cc.value) + parseFloat(copay.payment.cash.value);
      stateData.value.convenience_fee = (copay.payment.cc.value * 3) / 100
      stateData.value.total = total + stateData.value.convenience_fee
      stateData.value.coPayment = total;
    };

    const isAddNewRecipeInsurancePaymentActive = ref(false);
    const createRecipeInsurancePayment = () => {
      isAddNewRecipeInsurancePaymentActive.value = true;
      const data = {
        payment: stateData.value.coPayment,
        convenience_fee: stateData.value.convenience_fee,
        ...stateData.value,
      };
      const bloblstateData = bufferABase64(
        new TextEncoder().encode(JSON.stringify(data))
      );
      const routeData = router.resolve({
        name: "recipe-insurance-payment",
        query: { data: bloblstateData },
      });
      window.open(routeData.href, "_blank");
    };
    const bufferABase64 = (buffer) =>
      btoa(String.fromCharCode(...new Uint8Array(buffer)));

    const loading = ref(false);
    const refFormObserver = ref(null);
    const onSubmit = async () => {
      const check = await refFormObserver.value.validate();
      if (check) {
        loading.value = true;
        const phone = stateData.value.phone.replace(/[() -]/g, "");
        const datos = {
          phone,
          ...stateData.value,
        };

        let methodAxios = "";
        if (props.isOnlyCreatePatient) {
          methodAxios = "patientCreateOnly";
        } else {
          methodAxios = "patientCreate";
        }
        if (props.isInsuranceMedicalCenter) {
          methodAxios = "patientCreateInsurance";
        }
        await axiosPatient[methodAxios](datos)
          .then((res) => {
            const { type, message } = notificationCheck(res);
            if (props.isOnlyCreatePatient) {
              emit("createPatient", {
                type,
                message,
                idUser: res.registro.patient_id,
              });
            } else {
              emit("createPatient", {
                type,
                message,
              });
            }
            if (type != "danger" && stateData.isInsurance && stateData.isCoPayment) return createRecipeInsurancePayment()
            loading.value = false;
            resetstateData();
          })
          .catch(() => {
            loading.value = false;
            const { type, message } = notificationCheck(res);
            emit("createPatient", {
              type,
              message,
            });
          });
      }
    };

    const config = {
      dateFormat: "m-d-Y",
      allowInput: true,
      static: true,
    };
    return {
      loading,
      genderOptions,
      statusOptions,
      insuranceOptions,
      searchTypeInsurance,
      stateData,
      formatPhone,
      toast,
      checkCodeQr,
      checkCupon,
      onClickScanCupon,
      checkCuponModal,
      notificationCheckType,
      closeModalMembership,
      successModalCupon,
      closeModalCupon,
      cupon,
      noCupon,
      inputScan,
      scanPromotion,
      onHide,
      changeInsurance,
      onSubmit,
      onClickScanQr,
      checkCodeQrModal,
      conditionAddNewPatient,
      optionsTypeVisit,
      optionsTypeConsult,
      searchTypeVisit,
      searchTypeConsult,
      onSaveVisitReason,
      createRecipeInsurancePayment,
      isAddNewRecipeInsurancePaymentActive,
      changeCopaymentTotal,
      refFormObserver,
      config,
      medicationsOptions,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.button-custom {
  padding: 5px !important;
}

.flatpickr-wrapper {
  width: 100% !important;
}
</style>
