import { userAxios } from '../index'
import { apiV2 } from '../index'

const appointmentList = async (perPage) => {
    try {
        return await apiV2.get(`revenue-cycle-management/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentDetail = async (id) => {
    try {
        return await apiV2.get(`appointment/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentCreateInsurance = async (data) => {
    try {
        return await userAxios.post(`insurance-appointment`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentCreate = async (data) => {
    try {
        return await userAxios.post('appointment', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentUpdate = async (id, data) => {
    try {
        return await userAxios.put(`appointment/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentDelete = async (id) => {
    try {
        return await userAxios.delete(`appointment/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentPagination = async (perPage, page) => {
    try {
        return await apiV2.get(`revenue-cycle-management/index/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentFilter = async (perPage, data) => {
    try {
        return await apiV2.post(`revenue-cycle-management/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const searchTypeInsurance = async (perPage, data) => {
    try {
        return await apiV2.post(`insurance-type/filter/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const listInsuranceType = async (perPage) => {
    try {
        return await apiV2.get(`insurance-type/index/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentFilterPagination = async (perPage, page, data ) => {
    try {
        return await apiV2.post(`revenue-cycle-management/filter/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const appointmentPendingTreatment = async (patientId) => {
    try {
        return await userAxios.get(`appointment/validate-pending-procedures/${patientId}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const payMembership = async (data) => {
    try {
        return await userAxios.post(`membership/pay`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const updateMultiple = async (data) => {
    try {
        return await apiV2.post(`revenue-cycle-management/update/multiple`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const updatePendingPayment = async (data) => {
    try {
        return await apiV2.post(`revenue-cycle-management/update/pending-payment`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const updatePosting = async (data, id) => {
    try {
        return await apiV2.put(`revenue-cycle-management/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    appointmentList,
    appointmentDetail,
    appointmentCreate,
    appointmentCreateInsurance,
    appointmentUpdate,
    appointmentDelete,
    appointmentPagination,
    appointmentFilter,
    appointmentFilterPagination,
    appointmentPendingTreatment,
    payMembership,
    updateMultiple,
    updatePendingPayment,
    updatePosting,
    searchTypeInsurance,
    listInsuranceType
}