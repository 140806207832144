<template>
    <div class="text-nowrap">
      <b-button
        variant="flat-info"
        class="btn-icon btn-sm"
        v-b-tooltip.hover.top="'Add Appointment'"
        v-if="$can('store', 'module_patient.resource_schedule')"
        @click="addAppointment(dataOption)"
      >
        <feather-icon icon="PlusSquareIcon" size="16" />
      </b-button>
      <b-button
        variant="flat-warning"
        class="btn-icon btn-sm"
        v-b-tooltip.hover.top="'Edit Patient'"
        v-if="$can('update', 'module_patient.patient')"
        :to="{
          name: 'patients-view',
          params: { id: dataOption.id, isShow: true },
        }"
      >
        <feather-icon icon="EditIcon" size="16" />
      </b-button>
    </div>
</template>

<script>
import { BButton, VBTooltip } from "bootstrap-vue";
export default {
  components: {
    BButton
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    dataOption: {
      type: Object,
      required: false,
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  methods: {
    addAppointment(dataOption){
        this.$emit('addAppointment', dataOption)
    }
  }
};
</script>

<style></style>
