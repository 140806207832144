<template>
  <b-modal
    v-model="conditionCancel"
    @hide="onHide"
    hide-footer
    hide-header
    centered
    size="md"
  >
    <validation-observer #default="{}">
      <!-- internal payment note-->
      <b-tabs content-class="pt-1" fill>
        <b-tab active title="Lector">
          <!-- imagen de espera -->
          <b-img
            src="@/assets/images/banner/upgrade.png"
            rounded
            alt="lector"
          ></b-img>
          <b-form-input
            ref="miInput"
            v-model="scanQr"
            @input="empezarTemporizador"
            type="text"
            class="hidden-text"
          >
          </b-form-input>
          <!-- Hello - {{ scanQr }} -->
        </b-tab>
        <b-tab title="Camara">
          <qrcode-stream @decode="onDecode"> </qrcode-stream>
        </b-tab>
      </b-tabs>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormInput,
  BButton,
  BTabs,
  BTab,
  BImg,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { QrcodeStream } from "vue-qrcode-reader";

import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

import SpinnerLoading from "@/components/SpinnerLoading";

import axiosUS from "@/services/utilities/scan";

import { notificationCheck } from "@/mixins/NotificationSetup";

export default {
  components: {
    BModal,
    BForm,
    BFormInput,
    BButton,
    BTabs,
    BTab,
    BImg,

    flatPickr,
    vSelect,
    QrcodeStream,

    ValidationProvider,
    ValidationObserver,

    SpinnerLoading,
  },
  mounted() {
    this.focusInterval = setInterval(() => {
      this.$refs.miInput.focus();
    }, 1000);
  },
  data() {
    return {
      conditionCancel: true,
      cuponQr: "",
      mostrar: false,
      loading: false,
      scanQr: null,
      focusInterval: null,
      temporizador: null,
      tiempoEspera: 500, 
    };
  },
  methods: {
    empezarTemporizador() {
      clearTimeout(this.temporizador);
      this.temporizador = setTimeout(this.finalizarEscritura, this.tiempoEspera);
    },
    finalizarEscritura() {
      this.onSubmitQr();
    },
    onDecode(result) {
      this.scanQr = result;
      this.onSubmitQr();
    },
    onHide() {
      this.$emit("closeModalPay", false);
    },
    async onSubmitQr() {
      this.loading = true;
      const data = {
        scanQr: this.scanQr,
      };
      axiosUS
        .scanQrPatient(data)
        .then((res) => {
          this.loading = false;
          const data = {
            ...res,
            qr: this.scanQr,
          };
          this.$emit("closeModalQrPatient", data);
          this.$emit("closeModalPay", false);
          this.$emit("notificationCheck", notificationCheck(res));
        })
        .catch((e) => {
          this.loading = false;
          this.$emit("closeModalPay", false);
        });
    },
  },
  beforeDestroy() {
    clearInterval(this.focusInterval);
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.hidden-text {
  position: absolute;
  left: -9999px;
}
</style>
