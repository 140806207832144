<template>
  <b-card no-body>
    <b-card-header class="pb-50 cursor-pointer" @click="visible = !visible">
      <div class="d-flex justify-content-between">
        <h5>{{ $t("Filters") }}</h5>
        <feather-icon :icon="visible ? 'ArrowDownIcon' : 'ArrowUpIcon'" />
      </div>
    </b-card-header>
    <b-collapse v-model="visible">
      <b-card-body>
        <b-row>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("message.tableHeader.name") }}</label>
            <b-form-input
              autofocus
              v-model.trim="filters.name"
              @keyup.enter="searchFilter"
            />
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label> {{ $t("Form.City") }} </label>
            <v-select
              v-model="filters.cities_id"
              class="w-100"
              :options="stateOptions"
              label="name"
              :reduce="(value) => value.id"
              @search="searchState"
              @keyup.enter="searchFilter"
            />
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>DOB</label>
            <flat-pickr
              v-model="filters.date_birth"
              :config="config"
              class="form-control"
              placeholder="MM-DD-YYYY"
            />
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>Email</label>
            <b-form-input
              v-model.trim="filters.email"
              @keyup.enter="searchFilter"
            />
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("Form.Phone") }}</label>
            <b-form-input
              v-model.number="filters.phone"
              @keyup.enter="searchFilter"
            />
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("Form.Gender") }}</label>
            <v-select
              v-model="filters.gender"
              class="w-100"
              :options="genderOptions"
              :reduce="(value) => value.value"
              label="name"
              @keyup.enter="searchFilter"
            />
          </b-col>
          <b-col cols="12" md="3" class="mb-md-0 mb-2">
            <label>{{ $t("Form.Status") }}</label>
            <v-select
              v-model="filters.status"
              class="w-100"
              :options="statusOptions"
              :reduce="(value) => value.value"
              label="name"
              @keyup.enter="searchFilter"
            />
          </b-col>
          <b-col cols="12" class="mb-md-0 mb-2 mt-2" align="end">
            <b-button variant="warning" @click="resetFilter" class="mr-1">
              {{ $t("Clear") }}
            </b-button>
            <b-button
              variant="primary"
              @click="searchFilter"
              :disabled="loading"
            >
              <span v-if="!loading">{{ $t("Search") }}</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BCollapse
} from "bootstrap-vue";
import vSelect from "vue-select";
import axiosC from "@/services/admin/cities";

import SpinnerLoading from "@/components/SpinnerLoading";
import flatPickr from "vue-flatpickr-component";
import { fromConfig } from "@/mixins/configFormatCal";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BButton,
    BCollapse,
    vSelect,
    flatPickr,
    SpinnerLoading,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      setTimeoutBuscador: "",
      stateOptions: [],
      filters: {
        name: null,
        cities_id: null,
        date_birth: null,
        email: null,
        phone: null,
        gender: null,
        status: null,
      },
      genderOptions: [
        { name: this.$t("Male"), value: "male" },
        { name: "Female", value: "female" },
      ],
      statusOptions: [
        { name: "Active", value: "true" },
        { name: "Inactive", value: "false" },
      ],
      config: fromConfig({ dateFormat: "m-d-Y", }),
      visible: false
    };
  },
  methods: {
    searchFilter() {
      const data = this.filters;
      this.$emit("searchFilter", JSON.parse(JSON.stringify(data)));
      const { name, cities_id, date_birth, email, phone, gender, status } =
        this.filters;
        Object.keys(this.filters).forEach((key) => {
          if(key == null){
            this.$emit("filterPage", false);
          }else{
            this.$emit("filterPage", true);
          }
        });
      /* if (
        cities_id == null &&
        name == null &&
        date_birth == null &&
        email == null &&
        phone == null &&
        gender == null &&
        status == null
      ) {
        this.$emit("filterPage", false);
      } else {
        this.$emit("filterPage", true);
      } */
    },
    resetFilter() {
      const filter = JSON.parse(JSON.stringify(this.filters));
      for (const iterator in filter) {
        this.filters[iterator] = null;
      }
    },
    searchState(state, loading) {
      if (state.length) {
        loading(true);
        clearTimeout(this.setTimeoutBuscador);
        const datos = {
          name: state,
        };
        this.setTimeoutBuscador = setTimeout(() => {
          axiosC.citiesFilterFilters(datos).then(({ data }) => {
            loading(false);
            this.stateOptions = data;
          });
        }, 350);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
